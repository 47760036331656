import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { AlertController } from 'src/_services/alert.service';
import { ContractService } from 'src/_services/contract.service';
import Web3 from 'web3';

declare var $: any;
declare const themeSwitch: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Wire Foundation';
    faCoffee = faCoffee;
    tier: 't1' | 't2' | 't3' = 't1';

    tierSelected?: number;
    get selectedTier(): Tier | undefined {
        return this.tierSelected != undefined && this.tiers[this.tierSelected] ? this.tiers[this.tierSelected] : undefined;
    }
    get tierImage(): string | undefined {
        return this.selectedTier && this.selectedTier.image ? `https://ipfs.io/ipfs/${this.selectedTier.image.slice(7)}` : undefined
    }
    get tierVideo(): string | undefined {
        return this.selectedTier && this.selectedTier.video ? `https://ipfs.io/ipfs/${this.selectedTier.video.slice(7)}` : undefined
    }
    loadingTiers: boolean = false;

    connected: boolean = false;
    get address(): string {
        return this.contract.accounts.length ? this.contract.accounts[0].substring(0, 6) + '...' + this.contract.accounts[0].slice(-4) : '';
    }

    tiers: Tier[] = [];
    assets: number[] = [];
    token?:string;

    constructor(
        public contract: ContractService,
        private alertController: AlertController,
        private zone: NgZone,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.contract.on('contract-set').subscribe(() => {
            this.zone.run(this.onLoadNFTS.bind(this))
        })
        this.contract.on('networkChanged').subscribe(() => {
            // this.zone.run(this.onLoadNFTS.bind(this))
            this.tierSelected = undefined
        })
        this.contract.on('connected').subscribe(() => {
            // console.log('CONNECTED');
            this.zone.run(() => { this.connected = this.contract.connected; })
        })
        this.contract.on('disconnect').subscribe(() => {
            // console.log('DISCONNECT', this.contract.connected);
            this.zone.run(() => { this.connected = this.contract.connected; })
        })

        this.contract.setupContract();
    }

    ngOnInit(): void {
        var screen = { 'width': $(window).width(), 'height': $(window).height() };
        var body = $('body');
        var isDark = false;
        var initTier = "t1";

        this.route.queryParams.subscribe((params => {
            if(params.token) {
                this.token = params.token;
                console.log('TOKEN', params.token);
            }
        }))

        function themeSwitch() {
            body.toggleClass('theme-dark-user');
            if (body.hasClass("theme-dark-user") || body.hasClass("dark")) { isDark = true; $(".theme-switcher *").addClass("hide-dark"); } else { isDark = false; $(".theme-switcher *").removeClass("hide-dark"); }
        }

        $(".wire-mobile-menu-trigger, .wire-tier-trigger").on('click', function () {
            $(".wire-nav-links").toggleClass("wire-show-mobile-menu");
        });

        $("*[data-tier]:not(.wire-tier-trigger)").each(function () {
            if ($(this).data("tier") != initTier) {
                $(this).addClass("hide-tier");
                window.dispatchEvent(new Event('resize'));
            }
        });

        $(".wire-tier-trigger").on('click', function () {
            var tierToShow = $(this).data("tier");
            console.log(tierToShow);

            $("*[data-tier]:not(.wire-tier-trigger)").each(function () {
                if ($(this).data("tier") != tierToShow) {
                    $(this).addClass("hide-tier");
                } else {
                    $(this).removeClass("hide-tier");
                }
            });

            window.dispatchEvent(new Event('resize'));
        });

        $(".theme-switcher").on('click', function () {
            $('*').addClass("theme-switch");
            themeSwitch();
            setTimeout(function () {
                $('*').removeClass("theme-switch");
            }, 850);
        });

        document.addEventListener('scroll', function (e) {
            var currScroll = $(document).scrollTop();
            $(".theme-dark").each(function () {
                if (currScroll + (screen.height * 0.75) > $(this).offset().top && currScroll + (screen.height * 0.5) < $(this).offset().top + $(this).height()) {
                    isDark = true;
                    $(this).addClass("on-element");
                    $('*').addClass("theme-switch");
                    body.addClass("dark");
                    setTimeout(function () {
                        $('*').removeClass("theme-switch");
                    }, 850);
                }
            });

            if (isDark) {
                if (currScroll + (screen.height * 0.75) < $(".on-element").offset().top || currScroll + (screen.height * 0.5) > $(".on-element").offset().top + $(".on-element").height()) {
                    isDark = false;
                    $(".on-element").removeClass("on-element");
                    $('*').addClass("theme-switch");
                    body.removeClass("dark");
                    setTimeout(function () {
                        $('*').removeClass("theme-switch");
                    }, 850);
                }
            }
        });
    }


    async onLoadNFTS() {
        console.log('LOADING NFTS', this.loadingTiers || !this.contract.chain_matching);
        
        if (this.loadingTiers || !this.contract.chain_matching) return;
        this.loadingTiers = true;
        this.tiers = [];
        this.assets = await (await this.contract.getContractValue<Array<string>>('assets')).map(n => parseInt(n));
        console.log(this.assets);

        for (let id of this.assets) {
            let [_id, totalSupply, maxSupply, price] = await this.contract.getContractValue<Array<any>>('asset', id);
            let uri = await this.contract.getContractValue<string>('uri', id);
            console.log(uri, uri.slice(7));
            
            // let metadata = await this.contract.getMetaData<NFT>(`https://ipfs.io/ipfs/${uri.slice(7)}`);
            // console.log(metadata);
            let tier: Tier = {
                id,
                // name: metadata.name,
                name: '',
                description: '',
                totalSupply,
                maxSupply,
                // description: metadata.description
            }
            // if (metadata.image) tier.image = metadata.image;
            // if (metadata.animation_url) tier.video = metadata.animation_url;
            if (price) {
                tier.price = Web3.utils.fromWei(price, 'ether');
                tier.weiPrice = price;
            }
            console.log(tier);
            if(id == 1 && this.tierSelected == undefined) this.tierSelected = 0;
            this.tiers.push(tier);
        }

        console.log(this.tiers);
        
        this.loadingTiers = false;
    }


    async mint() {
        // this.minting_key = true;
        console.log('MINT', this.selectedTier);
        if (this.selectedTier == undefined) {
            return;
        }
        
        this.alertController.create({
            header: 'Are we the new meta?',
            message: `Are you sure you want to mint a ${this.selectedTier.name} node?`,
            buttons: [
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: async () => {
                        let interaction = await this.contract.mint(this.selectedTier!.id, 1, this.selectedTier!.weiPrice, this.token);
                        // if(this.selectedTier?.weiPrice) interaction = 
                        // else interaction = this.contract.contract!.methods.mint(this.selectedTier!.id, 1).send();
                        interaction
                            .on('error', (err: any) => {
                                // error or rejected
                                // this.minting_key = false;
                                this.zone.run(() => {
                                    this.contract.showToast({
                                        header: 'Rejected transaction',
                                        duration: 1000
                                    })
                                })
                            })
                            .on('transactionHash', (txHash: string) => {
                                // pending
                                console.log('TRX', txHash);
                                this.zone.run(() => {
                                    this.contract.showToast({
                                        header: 'Transaction Submitted',
                                        message: txHash.substring(0, 6) + '...' + txHash.slice(-6),
                                        linkText: 'View',
                                        duration: 10000
                                    }).then((snack) => {
                                        snack.onAction().subscribe(() => {
                                            console.log('OPEN');

                                            this.contract.openExplorer(txHash);
                                        });
                                    })
                                })
                            })
                            .on('receipt', (receipt: any) => {
                                // done
                                let txHash = receipt.transactionHash;
                                console.log('Receipt', receipt);
                                this.onLoadNFTS();
                                // this.minting_key = false;
                                this.zone.run(() => {
                                    this.contract.showToast({
                                        header: 'Success',
                                        message: txHash.substring(0, 6) + '...' + txHash.slice(-6),
                                        linkText: 'View',
                                        duration: 10000
                                    }).then((snack) => {
                                        snack.onAction().subscribe(() => {
                                            console.log('OPEN');
                                            this.contract.openExplorer(txHash);
                                        })
                                    })
                                })
                            })
                    }
                },
                {
                    text: 'No',
                    role: 'cancel',
                    handler: () => {
                        // this.minting_key = false;
                    }
                }
            ]
        }).then((alert) => {
            alert.present();
        });

    }

    async onConnect() {
        await this.contract.connect();
        await this.onLoadNFTS();
    }

    onSelect(index: number) {
        console.log('SELECT', index);
        
        this.tierSelected = index;
        // this.document.querySelector('#info')!.scrollIntoView();
    }
}

interface Tier {
    id: number;
    name: string;
    totalSupply: number;
    maxSupply: number;
    price?: string;
    weiPrice?: string;
    description: string;
    image?: string;
    video?: string;
}

interface NFT {
    name: string;
    description: string;
    image?: string;
    animation_url?: string;
}